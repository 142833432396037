<template>
  <div>
    <AppCard v-if="loading">
      <template #header>
        <XeStepperCardHeader analytics-name="xe-send-money-payment-direct-id" />
      </template>

      <AppSpinnerBig :loading="true" :inline="true" />
      <p class="text-center">Linking your account to XE...</p>
    </AppCard>

    <AppCard v-if="success">
      <template #header>
        <XeStepperCardHeader analytics-name="xe-send-money-payment-direct-id" />
      </template>

      <AppCardImageTitle
        :src="require('@galileo/assets/images/illustrations/success.svg')"
        title="Account has been connected successfully"
        alt="Success"
      >
        <p>You can now use your account</p>
      </AppCardImageTitle>

      <template #footer>
        <AppCardFooter>
          <AppButton analytics-name="send-payment-direct-id-continue" @click="verify">{{
            $t('PageSendMoneyVerifyIdentityEKYC.ButtonContinue').value
          }}</AppButton>
        </AppCardFooter>
      </template>
    </AppCard>

    <AppCard v-if="failed">
      <template #header>
        <XeStepperCardHeader analytics-name="xe-send-money-payment-direct-id" />
      </template>

      <AppCardImageTitle
        :src="require('@galileo/assets/images/illustrations/warning.svg')"
        title="Failed to link account"
        alt="Success"
      >
        <p>Please retry or use another account or another payment method</p>
      </AppCardImageTitle>

      <template #footer>
        <AppCardFooter>
          <AppButton analytics-name="send-payment-direct-id-retry" @click="retry">Retry</AppButton>
        </AppCardFooter>
      </template>
    </AppCard>
  </div>
</template>

<script>
import { ref, onBeforeMount } from '@vue/composition-api'
import { useRouter } from '@galileo/composables/useRouter'

import XeStepperCardHeader from '@galileo/components/XeStepperCardHeader/XeStepperCardHeader'

import { useI18nStore, useSendMoneyStore, useAppStore } from '@galileo/stores'

import {
  AppButton,
  AppCard,
  AppCardFooter,
  AppSpinnerBig,
  AppCardImageTitle,
  useMediaQuery,
} from '@oen.web.vue2/ui'
import { STEPS } from '@galileo/constants/sendMoneyFlow.const'

export default {
  name: 'SendMoneyPaymentDirectId',
  components: {
    AppCardImageTitle,
    AppButton,
    AppCard,
    XeStepperCardHeader,
    AppCardFooter,
    AppSpinnerBig,
  },

  setup() {
    const { $t } = useI18nStore()
    const router = useRouter()

    const loading = ref(true)
    const success = ref(false)
    const failed = ref(false)
    const appStore = useAppStore()
    const sendMoneyStore = useSendMoneyStore()

    // Tell the store which step we are on
    sendMoneyStore.setActiveStep(router.currentRoute.path)

    // Return the step data to the component
    const activeStep = sendMoneyStore.activeStep

    onBeforeMount(async () => {
      // url sample: http://localhost:8080/send-money/payment/directid-callback?state=success&customer_ref=test1&consent_id=4e04828f-301d-4a50-5745-08d9f097f321
      const urlParams = new URLSearchParams(window.location.search)
      const state = urlParams.get('state')
      const consentId = urlParams.get('consent_id')

      if (state === 'success') {
        try {
          await new Promise((resolve) => {
            window.setTimeout(() => {
              resolve(true)
            }, 1000)
          })
          loading.value = false
          success.value = true
          return
        } catch (ex) {
          appStore.logException('Exception during linking with DirectID', ex)
        }
      } else {
        appStore.logError('Invalid state from DirectID for consent ID ' + consentId, '', state)
      }

      if (!success.value) {
        loading.value = false
        failed.value = true
      }
    })

    const verify = () => {
      router.push({ name: STEPS.SEND_MONEY_SUMMARY })
    }
    const retry = () => {
      router.replace({ name: STEPS.SEND_MONEY_PAYMENT })
    }

    return {
      mq: useMediaQuery(),
      activeStep,
      verify,
      retry,
      loading,
      success,
      failed,
      $t,
    }
  },
}
</script>

<style scoped></style>
